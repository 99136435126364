
import Vue from 'vue'

export default Vue.extend({
  name: 'ExportIndex',

  data: () => {
    return {
    }
  },

  computed: {
    awards () {
      return this.$store.state.appInfo ? this.$store.state.appInfo.awards.activeAwards : null
    },
    currentAward () {
      return this.$store.state.currentAward
    },
    upcomingAwards () {
      return this.$store.state.appInfo ? this.$store.state.appInfo.awards.upcomingAwards : null
    },
    baseUrl () {
      return this.$store.state.baseUrl
    },
  },

  methods: {
    switchContext (val: any) {
      if (!val || (this.currentAward && val === this.currentAward.id)) {
        return this.$store.commit('setCurrentAward', null)
      }
      
      let res = this.awards?.filter((aw: any) => aw.id == val)[0]
      if (res) {
        this.$store.commit('setCurrentAward', res)
      }
    },
  },
})
